import { UUID } from '@neo1/client';

export enum Action {
  SET_RECIPIENT = 'one/recipient/SET_RECIPIENT',
  SET_RECIPIENTS = 'one/recipient/SET_RECIPIENTS',
  SET_RECIPIENT_COMPANY = 'one/recipient/SET_RECIPIENT_COMPANY',
  SET_RECIPIENT_COMPANIES = 'one/recipient/SET_RECIPIENT_COMPANIES',
}

type SetRecipient = {
  type: Action.SET_RECIPIENT;
  recipientId: UUID;
};

type SetRecipients = {
  type: Action.SET_RECIPIENTS;
  recipientsIds: UUID[];
};

type SetRecipientCompany = {
  type: Action.SET_RECIPIENT_COMPANY;
  recipientCompanyId: UUID;
};

type SetRecipientCompanies = {
  type: Action.SET_RECIPIENT_COMPANIES;
  recipientCompaniesIds: UUID[];
};

export const setRecipient = (recipientId: UUID): SetRecipient => ({
  type: Action.SET_RECIPIENT,
  recipientId,
});

export function setRecipients(recipientsIds: UUID[]): SetRecipients {
  return { type: Action.SET_RECIPIENTS, recipientsIds };
}

export function setRecipientCompany(
  recipientCompanyId: UUID,
): SetRecipientCompany {
  return { type: Action.SET_RECIPIENT_COMPANY, recipientCompanyId };
}

export function setRecipientCompanies(
  recipientCompaniesIds: UUID[],
): SetRecipientCompanies {
  return { type: Action.SET_RECIPIENT_COMPANIES, recipientCompaniesIds };
}

export type RecipientAction =
  | SetRecipient
  | SetRecipients
  | SetRecipientCompany
  | SetRecipientCompanies;
