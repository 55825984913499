import { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import Popover, { Ref as PopoverRef } from 'components/elements/Popover';
import styles from './DropDownList.module.css';

export type Item = {
  label: string;
  onClick: () => void;
};

type Props = {
  items: Item[];
  children: ReactNode;
};

const DropDownList = ({ items, children }: Props) => {
  const popoverRef = useRef<PopoverRef>();
  return (
    <Popover
      ref={popoverRef}
      content={
        <div className={classNames(styles.container, 'textM')}>
          {items.map(({ label, onClick }) => {
            const handleClick = () => {
              if (popoverRef.current) {
                popoverRef.current.hide();
              }
              onClick();
            };
            return (
              <button
                key={label}
                type="button"
                className={styles.item}
                onClick={handleClick}
                aria-label={label}
              >
                {label}
              </button>
            );
          })}
        </div>
      }
      placement="bottom-start"
      showOnClick
      hideArrow
    >
      {children}
    </Popover>
  );
};

export default DropDownList;
