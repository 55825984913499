/* eslint-disable import/prefer-default-export */

import isEmpty from 'lodash/isEmpty';
import { ROUTE_PATH_HOME } from 'modules/Dashboard/constants';
import { ROUTE_PATH_SPEND } from 'modules/Spend/constants';
import {
  ROUTE_PATH_RECEIPTS_SPEND,
  ROUTE_PATH_RECEIPTS_TRAVEL,
} from 'modules/Receipts/constants';

import {
  ROUTE_PATH_AUTHORIZE,
  ROUTE_PATH_AUTHORIZE_BUDGET,
  ROUTE_PATH_AUTHORIZE_PURCHASE,
} from 'modules/Authorize/constants';
import { ROUTE_PATH_PURCHASE_SUPPLIES } from 'modules/Supplies/constants';
import { ROUTE_PATH_PURCHASE_BUDGETS } from 'modules/Budgets/constants';
import {
  ROUTE_PATH_SETTINGS_EXPENSE_FINANCE,
  ROUTE_PATH_SETTINGS_EXPENSE_TAXES,
  ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS,
  ROUTE_PATH_SETTINGS_EXPENSE_TYPES,
  ROUTE_PATH_SETTINGS_EXPENSE_RULES,
  ROUTE_PATH_SETTINGS_EXPENSE_MILEAGE,
  ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO,
  ROUTE_PATH_SETTINGS_GENERAL_PEOPLE,
} from 'modules/Settings/constants';
import { ROUTE_PATH_TRAVEL } from 'modules/Travel/constants';
import { LocationData } from 'contexts/instrumentation/amplitude';
import { generatePath } from 'react-router';

function removeTrailingSlash(path: string): string {
  if (path.length !== 1) {
    return path.replace(/\/$/, '');
  }
  return path;
}

export function getEventNameByLocation({
  pathname,
  search,
}: LocationData): string {
  let eventName = '';
  let suffix = '';

  if (search.includes('v=justify')) {
    suffix = 'justify';
  } else if (search.includes('v=approve')) {
    suffix = 'approve';
  } else if (search.includes('v=review')) {
    suffix = 'review';
  } else if (search.includes('v=process')) {
    suffix = 'process';
  }

  switch (removeTrailingSlash(pathname)) {
    case generatePath(ROUTE_PATH_HOME):
      eventName = 'dashboard';
      break;
    case generatePath(ROUTE_PATH_AUTHORIZE):
      eventName = 'tab_post_purchase';
      break;
    case generatePath(ROUTE_PATH_AUTHORIZE_PURCHASE):
      eventName = 'tab_pre_purchase_supplies';
      break;
    case generatePath(ROUTE_PATH_AUTHORIZE_BUDGET):
      eventName = 'tab_pre_purchase_budgets';
      break;
    case generatePath(ROUTE_PATH_RECEIPTS_SPEND):
      eventName = 'tab_spend_receipts';
      break;
    case generatePath(ROUTE_PATH_RECEIPTS_TRAVEL):
      eventName = 'tab_travel_receipts';
      break;
    case generatePath(ROUTE_PATH_SPEND):
      eventName = 'spend_timeline';
      break;
    case generatePath(ROUTE_PATH_PURCHASE_BUDGETS):
      eventName = 'tab_budget';
      break;
    case generatePath(ROUTE_PATH_TRAVEL):
      eventName = 'tab_travel';
      break;
    case generatePath(ROUTE_PATH_PURCHASE_SUPPLIES):
      eventName = 'tab_supplies';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO):
      eventName = 'settings_general_business_info';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_GENERAL_PEOPLE):
      eventName = 'settings_general_users';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS):
      eventName = 'settings_general_payment_types';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_EXPENSE_FINANCE):
      eventName = 'settings_expense_accounting';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_EXPENSE_TAXES):
      eventName = 'settings_expense_taxes';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_EXPENSE_TYPES):
      eventName = 'settings_expense_types';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_EXPENSE_RULES):
      eventName = 'settings_expense_rules';
      break;
    case generatePath(ROUTE_PATH_SETTINGS_EXPENSE_MILEAGE):
      eventName = 'settings_expense_mileage';
      break;
    default:
      // Do nothing
      break;
  }

  return !isEmpty(suffix) ? `${eventName}_${suffix}` : eventName;
}
