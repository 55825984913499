import classNames from 'classnames';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';
import styles from './ToggleSwitch.module.css';

interface Props extends Omit<FieldWrapperProps, 'children'> {
  id?: string;
  isOn?: boolean;
  label?: string;
  reverse?: boolean;
  className?: string;
  onToggle?: () => void;
  testId?: string;
}

const ToggleSwitch = ({
  id,
  name,
  title,
  info,
  errorText,
  successText,
  helperText,
  keepBottomSpace,
  rightAlign,
  isInvalid,
  isRequired,
  isSuccess,
  isOn,
  disabled,
  readOnly,
  label,
  reverse,
  onToggle,
  className,
  testId,
}: Props) => {
  const handleChange = () => {
    if (!onToggle) return;
    onToggle();
  };

  return (
    <FieldWrapper
      id={id}
      name={name}
      title={title}
      info={info}
      errorText={errorText}
      successText={successText}
      helperText={helperText}
      keepBottomSpace={keepBottomSpace}
      rightAlign={rightAlign}
      isInvalid={isInvalid}
      disabled={disabled}
      isRequired={isRequired}
      isSuccess={isSuccess}
      noBorder
    >
      <div
        className={classNames(
          styles.wrapper,
          className,
          reverse && styles.reversed,
        )}
      >
        <input
          type="checkbox"
          id={id}
          checked={isOn}
          disabled={disabled || readOnly} // Note: readonly prop is not appliable to checkboxes
          onChange={handleChange}
          className={classNames(styles.container, disabled && styles.disabled)}
          data-testid={testId}
        />
        {Boolean(label) && (
          <label
            htmlFor={id}
            className={classNames(disabled && styles.disabledLabel)}
          >
            {label}
          </label>
        )}
      </div>
    </FieldWrapper>
  );
};

ToggleSwitch.defaultProps = {
  id: undefined,
  isOn: false,
  label: undefined,
  reverse: false,
  className: undefined,
  onToggle: undefined,
  testId: undefined,
};

export default ToggleSwitch;
