import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadReferential } from 'modules/App/redux/referential/thunk';
import {
  Country,
  Currency,
  CountryState,
} from '@neo1/client/lib/entities/referentialData/types';
import { singletonHook } from 'react-singleton-hook';
import {
  matchesCountryStateCode,
  hasStates,
} from '@neo1/client/lib/entities/referentialData/utils';
import useAsyncState from 'hooks/asyncState';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';
import selectAppReferential from './redux/referential/selectors';

export type AppReferential = {
  getCountryName: (code: string) => Country['name'];
  getCurrencyName: (code: string) => Currency['name'];
  getStateName: (countryCode: string, state: string) => CountryState['name'];
  getCountry: (code: string) => Country;
  getCountryProp: <K extends keyof Country>(
    prop: K,
    countryCode?: string,
  ) => Country[K];
  getCurrencyProp: <K extends keyof Currency>(
    prop: K,
    currencyCode?: string,
  ) => Currency[K];
  getCurrencies: () => Currency[];
  getCountries: () => Country[];
  isCountryWithStates: (code: string) => boolean;
  isReady: boolean;
};

const initialState: AppReferential = {
  getCountryName: () => '',
  getCurrencyName: () => '',
  getStateName: () => '',
  getCountry: () => undefined,
  getCountryProp: () => undefined,
  getCurrencyProp: () => undefined,
  getCurrencies: () => [],
  getCountries: () => [],
  isCountryWithStates: () => false,
  isReady: false,
};
/**
 * Lazy loader for app referential
 */
export const useAppReferential = (): AppReferential => {
  const dispatch = useDispatch();

  const { countries, currencies } = useSelector(selectAppReferential);
  /**
   * Gets a country from referential
   */
  const getCountry = useCallback(
    (code: string) => countries[code],
    [countries],
  );

  /**
   * Given a country code returns a boolean indicating if the corresponding country has states.
   */
  const isCountryWithStates = (countryCode: string) =>
    hasStates(getCountry(countryCode));

  /**
   * Resolves from app referential the wanted country property
   */
  const getCountryProp = useCallback(
    <K extends keyof Country>(prop: K, countryCode?: string): Country[K] => {
      const country = getCountry(countryCode);
      return country ? country[prop] : undefined;
    },
    [getCountry],
  );

  /**
   * Gets a state name if the given country has states, else returns state as given.
   */
  const getStateName = (countryCode: string, state: string) => {
    if (isCountryWithStates(countryCode)) {
      const countryState = getCountryProp('states', countryCode).find(
        matchesCountryStateCode(state),
      );
      return countryState?.name || state;
    }
    return state;
  };

  /**
   * Resolves from app referential the wanted currency property
   */
  const getCurrencyProp = useCallback(
    <K extends keyof Currency>(prop: K, currencyCode?: string): Currency[K] => {
      const currency = currencies[currencyCode];
      return currency ? currency[prop] : undefined;
    },
    [currencies],
  );
  const onLoadReferential = useCallback(
    async () => dispatch(loadReferential()),
    [dispatch],
  );

  const { error, execute, isReady } = useAsyncState(onLoadReferential);

  useEffect(() => {
    execute();
  }, [execute]);

  useEffect(() => {
    if (error) {
      dispatch(notifyError('An error occured. Please reload the page.'));
    }
  }, [dispatch, error]);

  return {
    getCountryName: (code: string) => getCountryProp('name', code),
    getCurrencyName: (code: string) => getCurrencyProp('name', code),
    getStateName,
    getCountry,
    getCountryProp,
    getCurrencyProp,
    getCurrencies: () => Object.values(currencies),
    getCountries: () => Object.values(countries),
    isCountryWithStates,
    isReady,
  };
};

export const useAppReferentialSingleton = singletonHook(
  { ...initialState },
  useAppReferential,
);
