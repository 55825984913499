import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import DatePicker, { DatePickerProps } from 'react-date-picker';
import dayjs from 'dayjs';
import { useDateUtils } from 'utils/date';
import Icon from 'components/elements/Icon';
import DatePickerPortal from '../DatePickerPortal';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';
import 'styles/reactCalendar.module.css';
import styles from './DateInput.module.css';

interface Props extends Omit<FieldWrapperProps, 'children'> {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  defaultView?: DatePickerProps['defaultView'];
  disabled?: boolean;
}

const DateInput = ({
  id,
  name,
  title,
  info,
  errorText,
  successText,
  helperText,
  keepBottomSpace,
  rightAlign,
  isInvalid,
  isRequired,
  isSuccess,
  value,
  onChange,
  onBlur,
  defaultView,
  disabled,
  readOnly,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();

  const targetRef = useRef(null);

  const pickerRef = useRef(null);

  const {
    inputDateFormat,
    inputYearPlaceholder,
    inputMonthPlaceholder,
    inputDayPlaceholder,
    formatDisplayDate,
    formatApiDate,
  } = useDateUtils();

  const selectedDate = value ? dayjs(value).toDate() : undefined;
  // by default the js Date maps 2-digit years 0 - 99 to 1900 – 1999
  // dayjs use the native js Date internally to parse date object from string so it has the same problem
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#interpretation_of_two-digit_years
  // and the setFullYear method could set the exact year, ex:
  // const date = new Date(98, 1) => Sun Feb 01 1998 00:00:00
  // date.setFullYear(98)         => Sat Feb 01 0098 00:00:00
  // here is the PR to fix this internally in dayjs
  // https://github.com/iamkun/dayjs/pull/1862
  // once its accepted and released, delete this little hack below
  if (selectedDate) selectedDate.setFullYear(parseInt(value.split('-')[0], 10));

  const handleOnChange = (date: Date) => {
    if (onBlur) {
      onBlur();
    }
    if (!onChange) {
      return;
    }
    if (!date) {
      onChange(null);
      return;
    }
    onChange(formatApiDate(date));
  };

  const handleOnOpen = () => setIsOpen(true);

  const handleOnClose = () => {
    setIsOpen(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <FieldWrapper
      id={id}
      name={name}
      title={title}
      info={info}
      errorText={errorText}
      successText={successText}
      helperText={helperText}
      keepBottomSpace={keepBottomSpace}
      rightAlign={rightAlign}
      isInvalid={isInvalid}
      disabled={disabled}
      isRequired={isRequired}
      isSuccess={isSuccess}
      readOnly={readOnly}
      readOnlyValue={value && formatDisplayDate(value)}
    >
      <div
        id={id}
        className={styles.container}
        ref={targetRef}
        data-testid={`${id}Input`}
      >
        <DatePickerPortal
          ref={pickerRef}
          targetRef={targetRef}
          isOpen={isOpen}
          testId={id}
        />
        <DatePicker
          className={classNames(styles.datePicker, styles.custom)}
          value={selectedDate}
          onChange={handleOnChange}
          onCalendarOpen={handleOnOpen}
          onCalendarClose={handleOnClose}
          defaultView={defaultView}
          locale={intl.locale}
          format={inputDateFormat}
          calendarIcon={
            <Icon name="calendar" className={styles.calendarIcon} />
          }
          clearIcon={<Icon name="close" className={styles.calendarIcon} />}
          openCalendarOnFocus={false}
          disabled={disabled}
          yearPlaceholder={inputYearPlaceholder}
          monthPlaceholder={inputMonthPlaceholder}
          dayPlaceholder={inputDayPlaceholder}
          portalContainer={pickerRef.current}
        />
      </div>
    </FieldWrapper>
  );
};

DateInput.defaultProps = {
  value: undefined,
  onChange: undefined,
  onBlur: undefined,
  defaultView: 'month',
  disabled: false,
};

export default DateInput;
