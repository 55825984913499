import {
  InputHTMLAttributes,
  FocusEvent,
  useRef,
  MutableRefObject,
  forwardRef,
} from 'react';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';
import { StringLengths } from '@neo1/client/lib';
import Icon, { IconName } from 'components/elements/Icon';
import Button, {
  ButtonTheme,
  ButtonSize,
  ButtonKind,
} from 'components/elements/Button';
import useForceFocus, { ForceFocusProps } from 'hooks/forceFocus';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';
import styles from './TextInput.module.css';

export interface TextInputProps
  extends Omit<
      InputHTMLAttributes<HTMLInputElement>,
      'id' | 'name' | 'onChange'
    >,
    ForceFocusProps,
    Omit<FieldWrapperProps, 'children'> {
  type?: string;
  maxLength?: number;
  iconName?: IconName;
  inputClassName?: string;
  onChange?: (v: string) => void;
  onTextClear?: () => void;
  withClearBtn?: boolean;
}

const TextInput = forwardRef(
  (
    {
      id,
      name,
      title,
      info,
      errorText,
      successText,
      helperText,
      keepBottomSpace,
      rightAlign,
      isInvalid,
      isRequired,
      isSuccess,
      readOnly,
      noBorder,
      onFocus: onFocusProp,
      onBlur: onBlurProp,
      autoComplete,
      defaultValue,
      disabled,
      className,
      onChange: onChangeProp,
      onKeyDown,
      onInput,
      maxLength,
      onKeyUp,
      placeholder,
      iconName,
      inputClassName,
      type,
      value,
      readOnlyValue,
      step,
      style,
      forceFocus,
      onTextClear,
      withClearBtn,
      ...props
    }: TextInputProps,
    ref: MutableRefObject<HTMLInputElement>,
  ) => {
    const ownRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || ownRef;

    useForceFocus(inputRef, forceFocus);

    const onFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (onFocusProp) {
        onFocusProp(e);
      }
    };

    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (onBlurProp) {
        onBlurProp(e);
      }
    };

    const onChange = (v: string) => {
      if (isFunction(onChangeProp)) {
        onChangeProp(v);
      }
    };

    const onClear = () => {
      onChange('');

      if (inputRef.current) {
        inputRef.current.focus();
      }

      if (onTextClear) {
        onTextClear();
      }
    };

    return (
      <FieldWrapper
        id={id}
        name={name}
        title={title}
        info={info}
        errorText={errorText}
        successText={successText}
        helperText={helperText}
        keepBottomSpace={keepBottomSpace}
        rightAlign={rightAlign}
        isInvalid={isInvalid}
        disabled={disabled}
        isRequired={isRequired}
        isSuccess={isSuccess}
        readOnly={readOnly}
        readOnlyValue={readOnlyValue || value}
        className={className}
        noBorder={noBorder}
      >
        <div
          className={classNames(styles.container, disabled && styles.disabled)}
          style={style}
        >
          {iconName && <Icon name={iconName} className={styles.icon} />}
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            id={id}
            value={value}
            onChange={(e) => onChange(e.currentTarget.value)}
            maxLength={maxLength}
            onBlur={onBlur}
            onFocus={onFocus}
            onInput={onInput}
            onKeyDown={onKeyDown}
            disabled={disabled}
            placeholder={placeholder}
            onKeyUp={onKeyUp}
            type={type}
            step={step}
            ref={inputRef}
            className={classNames(
              styles.input,
              inputClassName,
              iconName && styles.withIcon,
            )}
            data-testid={`${id}Input`}
          />
          {withClearBtn && Boolean(value) && (
            <Button
              label="Clear"
              iconName="close"
              iconOnly
              wrapperClassName={styles.btnClear}
              theme={ButtonTheme.Ghost}
              size={ButtonSize.Small}
              kind={ButtonKind.Neutral}
              onClick={onClear}
              disabled={disabled}
              testId={id ? `${id}_clearBtn` : undefined}
            />
          )}
        </div>
      </FieldWrapper>
    );
  },
);

TextInput.defaultProps = {
  type: 'text',
  maxLength: StringLengths.description,
  iconName: undefined,
  inputClassName: undefined,
  onChange: undefined,
  onTextClear: undefined,
  withClearBtn: true,
};

export default TextInput;
