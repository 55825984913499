export enum Actions {
  SET_EXPENSE_CATEGORIES = 'one/settings/ExpenseTypes/SET_EXPENSE_CATEGORIES ',
}

export type SetExpenseCategories = {
  type: Actions.SET_EXPENSE_CATEGORIES;
  ids: string[];
};

export function setAll(ids: string[]): SetExpenseCategories {
  return { type: Actions.SET_EXPENSE_CATEGORIES, ids };
}
