import { SpendRule } from '@neo1/client/lib/entities/spendRule/types';

export enum SpendRuleActionType {
  SET_RULES = 'one/settings/spendRules/SET_RULES',
  SET_RULE = 'one/settings/spendRules/SET_RULE',
  DEL_RULE = 'one/settings/spendRules/DEL_RULE',
}

export const setRules = (ids: SpendRule['id'][]): Action => ({
  type: SpendRuleActionType.SET_RULES,
  ids,
});
export const delRule = (id: SpendRule['id']): Action => ({
  type: SpendRuleActionType.DEL_RULE,
  id,
});
export const setRule = (id: SpendRule['id']): Action => ({
  type: SpendRuleActionType.SET_RULE,
  id,
});

export type Action =
  | {
      type: SpendRuleActionType.SET_RULES;
      ids: SpendRule['id'][];
    }
  | {
      type: SpendRuleActionType.DEL_RULE;
      id: SpendRule['id'];
    }
  | {
      type: SpendRuleActionType.SET_RULE;
      id: SpendRule['id'];
    };
