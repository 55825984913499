import uniq from 'lodash/uniq';
import { SpendRuleState } from './types';
import { SpendRuleActionType, Action } from './actions';

export const initialState: SpendRuleState = {
  ids: [],
};

export default (
  state: SpendRuleState = initialState,
  action: Action = null,
): SpendRuleState => {
  switch (action?.type) {
    case SpendRuleActionType.SET_RULES:
      return {
        ids: action.ids,
      };
    case SpendRuleActionType.SET_RULE:
      return {
        ids: uniq([...state.ids, action.id]),
      };
    case SpendRuleActionType.DEL_RULE:
      return {
        ids: state.ids.filter((id) => id !== action.id),
      };
    default:
      return state;
  }
};
