import { Group } from '@neo1/client/lib/entities/group/types';

export enum Action {
  SET_GROUPS = 'one/recipient/SET_GROUPS',
}
type SetGroups = {
  type: Action.SET_GROUPS;
  groupsIds: Group['id'][];
};

export function setGroups(groupsIds: Group['id'][]): SetGroups {
  return { type: Action.SET_GROUPS, groupsIds };
}

export type GroupAction = SetGroups;
